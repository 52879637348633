<template>
  <div v-if="synthesisPlan.attributesStructure.length > 0" class="w-full p-4">
    <table class="w-full" aria-describedby="attributes builder">
      <thead>
        <tr>
          <th class="text-left text-primary">Scope</th>
          <th class="text-left text-primary">Question</th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="structure in synthesisPlan.attributesStructure"
          :key="'structure-' + structure.id"
          class="even:bg-slate-50"
        >
          <AttributeStructureBuilder
            :key="structure.id"
            :structure="structure"
            @delete-attribute-structure="deleteAttributesStructure"
          />
        </tr>
      </tbody>
    </table>
    <button
      v-if="!review.isPlanReadonly.value"
      class="border-2 border-dashed mt-4 rounded-lg border-blue-300/70 hover:bg-blue-50 hover:text-primary text-primary/70 bg-blue-50 flex justify-center items-center gap-2 px-5 py-4 w-full"
      @click.stop="addAttributesStructure()"
    >
      <PlusIcon class="w-5" /> Add Scope
    </button>
  </div>
</template>
<script setup lang="ts">
import AttributeStructureBuilder from './AttributeStructureBuilder.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import { SynthesisPlan } from '@core/domain/models/data-extraction-plan.model'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { PlusIcon } from '@heroicons/vue/24/outline'

const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()
const synthesisPlan = defineModel<SynthesisPlan>({
  default: () => [],
})

async function deleteAttributesStructure(attributeStructureId: string) {
  try {
    loading.start()
    await review.deleteAttributeStructure(
      review.entity.value.id,
      attributeStructureId,
    )
    snackbar.show(
      SnackbarState.SUCCESS,
      'attribute structure deleted successfully',
    )
    const index = synthesisPlan.value.attributesStructure.findIndex(
      (attr) => attr.id === attributeStructureId,
    )

    synthesisPlan.value.attributesStructure.splice(index, 1)
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function addAttributesStructure() {
  try {
    loading.start()
    await review.addAttributeStructure(review.entity.value.id, {
      label: 'Scope label',
      question: '',
      order:
        synthesisPlan.value.attributesStructure.length > 0
          ? Math.max(
              ...synthesisPlan.value.attributesStructure.map(
                (attr) => attr.order,
              ),
            ) + 1
          : 1,
    })
    snackbar.show(
      SnackbarState.SUCCESS,
      'attribute structure added successfully',
    )
  } catch (e) {
    const error = e as HttpException

    snackbar.show(
      SnackbarState.ERROR,
      'an error occured during adding attribute structure',
    )
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
