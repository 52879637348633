<template>
  <div class="h-full">
    <div class="flex flex-1 pb-0 pr-0 gap-2 h-full">
      <div class="flex gap-4 w-full relative h-full">
        <div class="w-full relative">
          <AttributeTextField
            :model-value="attributeValue"
            :placeholder="attributeStructure.label"
            :is-expanded="isExpanded"
            :is-review-readonly="review.isReviewReadonly.value"
            @save="updateAttributeValue"
          />
          <div class="absolute top-2 right-2">
            <StudyQA
              v-if="!review.isReviewReadonly.value"
              v-model="attributeValue"
              :study="study"
              :attribute-structure="attributeStructure"
              :query="attributeStructure.question"
              :is-review-readonly="review.isReviewReadonly.value"
              @save="updateAttributeValue"
            />
          </div>

          <div
            v-if="attributeValue.length === 0"
            class="absolute bottom-2 right-2 flex items-center justify-center"
          >
            <FloatingMenu :offset="[0, 5]" placement="right" trigger="hover">
              <template #activator>
                <button
                  class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center bg-red-100 text-red-800 fill-red-800"
                  @click="
                    updateAttributeValue(
                      'The record does not include information about this question.',
                    )
                  "
                >
                  No data
                </button>
              </template>
              <template #content>
                <div class="w-64">
                  Confirm that the record does not include information about
                  this question.
                </div>
              </template>
            </FloatingMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import AttributeTextField from './AttributeTextField.vue'
import StudyQA from './StudyQA.vue'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import { HttpException } from '@core/exceptions/http.exception'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'

const props = withDefaults(
  defineProps<{
    attributeStructure: AttributeStructure
    study: ReviewItem
    isExpanded: boolean
    isReviewReadOnly?: boolean
  }>(),
  {
    isReviewReadOnly: false,
  },
)

const review = injectStrict(ReviewKey)

const attributeValue = ref(
  props.study.synthesis.attributes.find(
    (a) => a.attributeStructureId === props.attributeStructure.id,
  )?.value ?? '',
)

const snackbar = useSnackbar()
const loading = useLoading()

async function updateAttributeValue(value: string) {
  try {
    loading.start()
    await review.updateStudySynthesisAttribute(
      review.entity.value.id,
      props.study.id,
      { attributeStructureId: props.attributeStructure.id, value: value },
    )
    snackbar.show(SnackbarState.SUCCESS, 'attribute value updated successfully')
    attributeValue.value = value
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>

<style>
.loader {
  border: 4px solid var(--color-primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
